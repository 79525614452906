import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Row>
        <Col>
        <h1>NOT FOUND</h1>
    <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
  
        </Col>
      </Row>
    </Container>
    </Layout>  
)

export default NotFoundPage
